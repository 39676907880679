<!-- 解决方案二级页面 -->
<template>
  <div class="wrapper pageBox">
    <div v-if="isShow" class="banner">
      <el-carousel>
        <el-carousel-item v-for="(item, index) in banner" :key="'banner' + index">
          <div class="div">
            <img :src="item.img">
            <div class="bannerText">
              <div class="title">{{ item.title }}</div>
              <div class="text" style="width: 980px">{{ item.content }}</div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 面包屑 -->
    <div class="breadCrumbs">
      <div class="text">
        <div v-for="(item, index) in breadCrumbs" :key="index" class="breadCrumb">
          <div :class="index === 1 ? 'breadCrumbItem' : ''" @click="goto(item, index)">{{ item.name }}</div>
          <span v-show="index !== breadCrumbs.length - 1">&gt;</span>
        </div>
      </div>
    </div>
    <!-- 内容区域 -->
    <div v-if="isShow" class="connect">
      <!-- 趋势分析 -->
      <div v-for="(item, index) in trend" :key="'trend' + index">
        <div class="topText trend-title">
          <div class="title">{{ item.title }}</div>
        </div>
        <div class="conBox">
          <div class="trend-tit">{{ item.subTitle }}</div>
          <div class="trend-box">
            <div class="trend-content">
              <p>{{ item.content }}</p>
              <template v-for="(o, i) in item.steps">
                <p :key="'tit' + i" class="tit">{{ o.title }}</p>
                <p :key="'p' + i">{{ o.content }}</p>
              </template>
            </div>
            <img :src="item.img" alt="">
          </div>
        </div>
      </div>
      <!-- 业务挑战 -->
      <div v-for="(item, index) in challenge" :key="'challenge' + index" class="challenge-box">
        <div class="topText challenge-title">
          <div class="title">{{ item.title }}</div>
        </div>
        <div class="challenge-content">
          <div v-for="(o, i) in item.steps" :key="'o' + i" class="chalenge-item">
            <div class="left">
              <img :src="o.img" alt="">
            </div>
            <div class="right">
              <div class="item-tit">{{ o.title }}</div>
              <div class="item-content">
                {{ o.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="conBox">
        <!-- 解决方案场景 -->
        <div v-for="(item, index) in solutionScene" :key="'solutionScene' + index">
          <div class="solution-scene-title topText">
            <div class="title">{{ item.title }}</div>
          </div>
          <div v-show="notEmpty(item.steps)" class="solution-scene-box">
            <div v-for="(obj, idx) in item.steps" :key="'solutionSceneSteps' + idx" class="solution-scene-item">
              <div class="item-left">
                <div class="imgBox">
                  <img :src="obj.img" alt="">
                </div>
                <div class="title">{{ obj.title }}</div>
                <div
                  class="content"
                  v-html="obj.content"
                />
              </div>
              <div v-if="idx !== (item.steps.length - 1)" class="item-right">
                <img src="https://zhongjia-data.oss-cn-hangzhou.aliyuncs.com/web/assets/img/arrowRight.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <!-- 卡片展示区域—— 5 -->
        <div v-if="tabList.length === 5" class="card-box">
          <template v-for="(item, index) in tabList">
            <div v-if="index === 0 || index === 1" :key="'big' + index" class="card-big card-item">
              <div
                class="title"
                :style="{ 'background-image': 'url(' + item.img + ')', 'background-size': 'cover', 'background-position': 'center' }"
              >
                <div class="mask" />
                <span class="text">{{ item.title }}</span>
              </div>
            </div>
            <div v-if="index !== 0 && index !== 1" :key="'small' + index" class="card-medium card-item">
              <div
                class="title"
                :style="{ 'background-image': 'url(' + item.img + ')', 'background-size': 'cover', 'background-position': 'center' }"
              >
                <div class="mask" />
                <span class="text">{{ item.title }}</span>
              </div>
            </div>
          </template>
        </div>
        <!-- 卡片展示区域—— 4 -->
        <div v-if="tabList.length === 4" class="card-box">
          <template v-for="(item, index) in tabList">
            <div :key="'small' + index" class="card-small card-item">
              <div class="title" :style="{ 'background-image': 'url(' + item.img + ')', 'background-size': 'cover', 'background-position': 'center' }">
                <div class="mask" />
                <span class="text">{{ item.title }}</span>
              </div>
            </div>
          </template>
        </div>
        <!-- 卡片展示区域—— 3 -->
        <div v-if="tabList.length === 3" class="card-box">
          <template v-for="(item, index) in tabList">
            <div :key="'small' + index" class="card-medium card-item">
              <div class="title" :style="{ 'background-image': 'url(' + item.img + ')', 'background-size': 'cover', 'background-position': 'center' }">
                <div class="mask" />
                <span class="text">{{ item.title }}</span>
              </div>
            </div>
          </template>
        </div>
        <!-- 解决方案架构 -->
        <div v-for="(item, index) in solutionArchitecture" :key="'solutionArchitecture' + index">
          <div class="solution-architecture-title topText">
            <div class="title">{{ item.title }}</div>
          </div>
          <div class="solution-architecture-box">
            <div class="left">
              <img :src="item.img" alt="">
            </div>
            <div class="right">
              <div class="tit">{{ item.subTitle }}</div>
              <div v-for="(obj, idx) in item.steps" :key="idx" class="li">
                <div class="head">
                  {{ obj.title }}
                </div>
                <div class="text">
                  {{ obj.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 方案优势 -->
      <div v-for="(item, index) in planAdvantage" :key="'planAdvantage' + index">
        <div class="solution-architecture-title topText">
          <div class="title">{{ item.title }}</div>
        </div>
        <div class="plan-advantage-box">
          <div class="plan-advantage">
            <div v-for="(obj, idx) in item.steps" :key="idx" class="plan-advantage-item">
              <div class="item-tit">{{ obj.title }}</div>
              <div class="item-content">{{ obj.content }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 客户案例 -->
      <div v-if="notEmpty(newsList)">
        <div class="solution-architecture-title topText">
          <div class="title">客户案例</div>
        </div>
        <div class="customer-case-box">
          <div class="btn prev" @click="prev">
            <i class="el-icon-arrow-left" />
          </div>
          <el-carousel ref="carousel" indicator-position="none" :autoplay="isAutoplay" arrow="never" trigger="click" @change="change">
            <el-carousel-item v-for="(item, index) in newsList" :key="index">
              <div class="carouselBlock">
                <img class="img" :src="item.shortPic">
                <div class="msg">
                  <div class="title">{{ item.title }}</div>
                  <div class="time">{{ item.createData }}</div>
                  <div class="text">{{ item.desction }}</div>
                  <div class="more" @click="clickMore(item)">查看标杆案例>></div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="btn next" @click="next">
            <i class="el-icon-arrow-right" />
          </div>
        </div>
      </div>
    </div>
    <div class="connect">
      <empty v-if="!isShow" />
    </div>
  </div>
</template>

<script>
// 接口
import { getNavListConfig, getNewsList } from '@/service/api';
import empty from '@/components/Empty/Index.vue';
export default {
  name: 'Second',
  components: {
    empty
  },
  data() {
    return {
      breadCrumbs: [],
      banner: [{
        title: '',
        img: '',
        content: ''
      }],
      trend: [{
        title: '',
        subTitle: '',
        img: '',
        content: '',
        steps: [{
          title: '',
          content: ''
        }]
      }],
      challenge: [{
        title: '',
        content: [
          {
            img: '',
            title: '',
            content: ''
          }]
      }],
      solutionScene: [{
        title: '',
        steps: [{
          img: '',
          title: '',
          content: ['']
        }]
      }],
      tabList: [{
        title: '',
        img: '',
        content: ''
      }],
      solutionArchitecture: [{
        title: '',
        subTitle: '',
        img: '',
        steps: [{
          title: '',
          content: ''
        }]
      }],
      planAdvantage: [{
        title: '',
        steps: [{
          title: '',
          content: ''
        }]
      }],
      activeTab: 0,
      isAutoplay: false,
      newsList: [{
        shortPic: '',
        title: '',
        createData: '',
        desction: ''
      }],
      isShow: false
    };
  },
  computed: {
    navitems() {
      return this.$store.state.navItems;
    }
  },
  watch: {
    '$route.query.id': {
      immediate: true,
      deep: true,
      handler(val) {
        this.getNavListConfig();
        this.getNewsList();
        const timer = setInterval(() => {
          if (this.navitems) {
            this.breadCrumbs = this.findPName(this.navitems, val / 1);
            clearInterval(timer);
          }
        }, 50);
      }
    }
  },
  created() {},
  mounted() {},
  methods: {
    getNavListConfig() {
      const id = this.$route.query.id;
      getNavListConfig(id).then((res) => {
        if (this.notEmpty(res.data)) {
          this.banner = res.data.banner;
          this.trend = res.data.trend;
          this.challenge = res.data.challenge;
          this.solutionScene = res.data.solutionScene;
          this.tabList = res.data.tabList;
          this.solutionArchitecture = res.data.solutionArchitecture;
          this.planAdvantage = res.data.planAdvantage;
          this.isShow = true;
        } else {
          this.banner = [{
            title: '',
            img: '',
            content: ''
          }];
          this.trend = [{
            title: '',
            subTitle: '',
            img: '',
            content: '',
            steps: [{
              title: '',
              content: ''
            }]
          }];
          this.challenge = [{
            title: '',
            content: [
              {
                img: '',
                title: '',
                content: ''
              }]
          }];
          this.solutionScene = [{
            title: '',
            steps: [{
              img: '',
              title: '',
              content: ['']
            }]
          }];
          this.tabList = [{
            title: '',
            img: '',
            content: ''
          }];
          this.solutionArchitecture = [{
            title: '',
            subTitle: '',
            img: '',
            steps: [{
              title: '',
              content: ''
            }]
          }];
          this.isShow = false;
        }
      });
    },
    // 获取客户案例
    getNewsList() {
      const params = {
        caseStudyFlag: 1,
        navId: this.$route.query.id
      };
      getNewsList(params).then((res) => {
        this.newsList = res.page.list;
      });
    },
    // 点击查看客户案例
    clickMore(item) {
      this.$router.push({
        path: '/news/info',
        query: {
          id: item.id,
          navId: item.navId
        }
      });
    },
    clickTab(index) {
      this.activeTab = index;
    },
    prev() {
      this.isAutoplay = false;
      if (this.lastIndex === 0) {
        this.lastIndex = 3;
      } else {
        this.lastIndex--;
      }
      this.$refs.carousel.prev();
      setTimeout(() => {
        this.isAutoplay = false;
      }, 1000);
    },
    next() {
      this.isAutoplay = false;
      if (this.lastIndex >= this.tabList.length - 1) {
        this.lastIndex = 0;
      } else {
        this.lastIndex++;
      }
      this.$refs.carousel.next();
      setTimeout(() => {
        this.isAutoplay = true;
      }, 1000);
    },
    change(index) {
      this.lastIndex = index;
    },
    findPName(list, id) {
      for (let i = 0; i < list.length; i++) {
        for (let j = 0; j < list[i].child.length; j++) {
          for (let x = 0; x < list[i].child[j].child.length; x++) {
            if (list[i].child[j].child[x].id === id) {
              const arr = [list[i], list[i].child[j], list[i].child[j].child[x]];
              return arr;
            }
          }
        }
      }
    },
    goto(item, index) {
      if (index === 1) {
        this.$router.push({
          path: item.pathUrl,
          query: {
            id: item.id
          }
        });
      }
    }
  }
};
</script>

<style lang='scss' scoped>
.banner {
  height: 400px;
  /deep/ .el-carousel__container {
    height: 400px;
    img {
      display: block;
      margin: 0 auto;
      height: 100%!important;
      width: auto!important;
    }
  }
  .bannerText {
    top: 123px;
    .title {
      font-size: 40px;
      font-weight: 500;
    }
    .text {
      width: 600px;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 1px;
    }
  }
}
.breadCrumbs {
  width: 1920px;
  height: 40px;
  background: #f7f9fc;
  .text {
    width: 1280px;
    margin: 0 auto;
    line-height: 40px;
    color: #9299a5;
    display: flex;
    .breadCrumb {
      display: flex;
      margin-right: 10px;
      .breadCrumbItem {
        &:hover {
          cursor: pointer;
          font-weight: 700;
          color: #144fd1;
        }
      }
      div {
        cursor: default;
        margin-right: 10px;
      }
      &:nth-child(1) {
        margin-left: 0;
      }
      &:last-child {
        color: #666;
      }
    }
  }
}
.connect {
  .topText.trend-title {
    height: 220px;
  }
  .topText.challenge-title {
    height: 175px;
    .title {
      margin-top: 56px;
    }
  }
  .topText.solution-scene-title {
    height: 200px;
  }
  .topText.solution-architecture-title {
    height: 200px;
  }
  .conBox {
    width: 1280px;
    margin: 0 auto;
    .trend-tit {
      font-size: 18px;
      font-weight: 700;
      text-align: left;
      color: #252b3a;
      margin-bottom: 10px;
    }
    .trend-box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 100px;
      .trend-content {
        width: 608px;
        font-size: 14px;
        font-weight: 400;
        color: #6a6e77;
        line-height: 23px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        p {
          font-family: 'siyuan-face'!important;
          &.tit {
            margin-top: 8px;
            // font-weight: 700;
            color: #575d6c;
          }
        }
      }
      img {
        width: 626px;
        height: 388px;
      }
    }
    .solution-scene-box {
      display: flex;
      justify-content: space-between;
      height: 200px;
      margin-bottom: 100px;
      .solution-scene-item {
        display: flex;
        .item-left {
          display: flex;
          flex-direction: column;
          align-items: center;
          .imgBox {
            width: 58px;
            height: 58px;
            margin-bottom: 20px;
            img {
              width: 58px;
            }
          }
          .title {
            font-size: 18px;
            font-weight: 700;
            color: #252b3a;
            margin-bottom: 18px;
          }
          /deep/.content {
            font-family: 'siyuan-face'!important;
            width: 165px;
            font-size: 14px;
            text-align: left;
            color: #6a6e77;
            line-height: 24px;
          }
        }
        .item-right {
          img {
            margin-top: 15px;
            width: 40px;
            height: 25px;
          }
        }
      }
    }
    .tabs-box {
      margin-bottom: 40px;
      .tab {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-bottom: 18px;
        .tab-item {
          cursor: pointer;
          font-size: 18px;
          font-weight: 400;
          color: #575d6c;
          &.selected {
            font-weight: 700;
            color: #252b3a;
            .bgcBlock {
              height: 3px;
              width: 100%;
              background-color: #144fd1;
              position: relative;
              bottom: -18px;
            }
          }
          &:hover {
            font-weight: 700;
            color: #252b3a;
          }
        }
      }
      .tab-line {
        width: 100%;
        height: 1px;
        background-color: #dee0e5;
      }
    }
    .tab-content {
      display: flex;
      justify-content: space-around;
      .left {
        width: 500px;
        margin-top: 50px;
        .title {
          font-size: 24px;
          font-weight: 700;
          color: #252b3a;
          margin-bottom: 35px;
        }
        .content {
          font-size: 14px;
          font-weight: 400;
          color: #6a6e77;
          line-height: 24px;
        }
      }
      .right {
        width: 564px;
        height: 340px;
      }
    }
    .card-box {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      // margin-top: 20px;
      .card-item {
        height: 200px;
        padding: 10px;
        .title {
          &:hover {
            height: 104%;
            width: 104%;
            transform: translate(-2%, -2%);
            .mask {
              // background-color: rgba(0, 0, 0, 0);
            }
            .text {
              line-height: 10px;
            }
          }
          font-size: 20px;
          font-weight: 700;
          line-height: 180px;
          height: 100%;
          color: #fff;
          position: relative;
          .mask {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
          }
          .text {
            line-height: 180px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .card-big {
        width: 50%;
      }
      .card-medium {
        width: 33.3%;
      }
      .card-small {
        width: 25%;
      }
    }
    .solution-architecture-box {
      display: flex;
      justify-content: space-between;
      .left {
        border: 1px solid #e7eaef;
        height: 532px;
        img {
          width: 698px;
          height: 504px;
          margin: 13px 14px;
        }
      }
      .right {
        padding: 47px 36px 0 49px;
        width: 490px;
        height: 532px;
        border-radius: 4px;
        box-shadow: 0 0 10px 6px rgba(100, 112, 139, .06);
        .tit {
          margin-left: 10px;
          margin-bottom: 12px;
          font-size: 20px;
          color: #252B3A;
          font-family: 'siyuan-medium' !important;
        }
        .li {
          font-size: 16px;
          .head {
            margin-top: 22px;
            margin-bottom: 6px;
            color: #252B3A;
            font-family: 'siyuan-medium' !important;
          }
          .text {
            font-size: 14px;
            margin-left: 10px;
            color: #6A6E77;
            line-height: 24px;
          }
        }
      }
    }
  }
  .challenge-box {
    width: 100%;
    height: 483px;
    background: rgba(246,247,249,0.50);
    .challenge-content {
      width: 1280px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      .chalenge-item {
        width: calc(50% - 15px);
        display: flex;
        margin-right: 30px;
        margin-bottom: 25px;
        &:nth-child(2n) {
          margin-right: 0;
        }
        .left {
          width: 81px;
          img {
            width: 54px;
            height: 54px;
          }
        }
        .right {
          flex: 1;
          // width: 530px;
          .item-tit {
            font-size: 18px;
            font-weight: 700;
            color: #252b3a;
            line-height: 24px;
            margin-bottom: 5px;
          }
          .item-content {
            font-size: 14px;
            color: #6a6e77;
            line-height: 24px;
            height: 80px;
          }
        }
      }
    }
  }
  .plan-advantage-box {
    background: rgba(242,244,246,0.70);
    overflow: hidden;
    .plan-advantage {
      display: flex;
      justify-content: space-between;
      width: 1280px;
      margin: 0 auto;
      margin-top: 60px;
      margin-bottom: 60px;
      .plan-advantage-item {
        width: 412px;
        height: 230px;
        background: #ffffff;
        border-radius: 4px;
        padding: 30px 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:hover {
          box-shadow: 0 0 10px 6px rgba(100, 112, 139, .06);
        }
        .item-tit {
          width: 370px;
          font-size: 19px;
          font-weight: 700;
          text-align: center;
          color: #252b3a;
          line-height: 30px;
          margin-bottom: 34px;
        }
        .item-content {
          width: 368px;
          font-size: 14px;
          font-weight: 400;
          text-align: left;
          color: #6a6e77;
          line-height: 24px;
        }
      }
    }
  }
  .customer-case-box {
    margin: 0 auto;
    max-width: 1560px;
    display: flex;
    align-items: center;
    margin-bottom: 80px;
    .btn {
      background: #ECECEC;
      width: 50px;
      height: 50px;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: #e0e0e0;
      }
      i {
        font-size: 28px;
        font-weight: bold;
        color: #fff;
      }
    }
    .el-carousel {
      height: 260px;
      width: 1282px;
      overflow: hidden;
      margin: 0 auto;
      position: relative;
    }
    /deep/ .el-carousel__arrow {
      display: none;
    }
    /deep/.el-carousel__button {
      background-color: #d7d9de;
      width: 4px;
      height: 4px;
      border-radius: 2px;
    }
    .carouselBlock {
      display: flex;
      position: absolute;
      left: 50%;
      top: 0;
      width: 100%;
      transform: translateX(-50%);
      .img {
        margin-right: 36px;
        width: 400px;
        height: 260px;
      }
      .msg {
        box-sizing: border-box;
        padding: 0 68px 0 63px;
        width: 843px;
        background: #F4F7FA;
        color: #6A6E77;
        font-size: 14px;
        .title {
          padding-top: 28px;
          padding-bottom: 7px;
          color: #354059;
          font-size: 24px;
          font-weight: 500;
          font-family: 'siyuan-medium' !important;
        }
        .time {
          margin-bottom: 16px;
          color: #A0A0A0;
        }
        .text {
          margin-bottom: 21px;
          line-height: 24px;
        }
        .more {
          color: #5981D8;
          cursor: pointer;
          font-family: 'siyuan-medium' !important;
        }
      }
    }
  }
}
</style>
